<template>
	<el-table class="jscx-terms-table" height="auto" ref="multipleTable" :data="filterData" @select="pinSelect"
		@sort-change="sortChange" size="mini" border :default-sort ="sort" :key="tableKey">
		<el-table-column v-for="tableColumn, index in tableColumns" :label="tableColumn.label" :prop="tableColumn.prop"
			min-width="180" align="center" :sortable="tableColumn.sortable" :formatter="cellFormatter">
		</el-table-column>
	</el-table>
</template>

<script>
	import MultipleTableSelection from '@/common/mixins/multiple-table-selection.js'
	import {
		mapGetters
	} from 'vuex'
	import Moment from 'moment'
	export default {
		mixins: [MultipleTableSelection],
		props: {
			tableData: {
				type: Array,
				default: () => []
			},
			tableColumns: {
				type: Array,
				default: () => []
			},
			tableProps: {
				type: Object,
				default: () => {}
			},
			page: {
				type: Object,
				default: () => {
					return {
						current: 1,
						size: 50
					}
				}
			}
		},
		data() {
			return {
				sort: {
					prop: 'epc',
					order: 'descending'
				}
			}
		},
		computed: {
			tableKey() {
				return 'jscx-terms-table-' + this.groupByDays
			},
			groupByDays() {
				try {
					return this.tableProps['groupByDays']
				} catch(e) {}
				return false
			},
			dateRange() {
				try {
					return this.tableProps['dateFrom'] + '~' + this.tableProps['dateTo']
				} catch(e) {}
				return '-'
			},
			filterData() {
				return this.sortedData.slice((this.page.current-1)*this.page.size, this.page.current* this.page.size)
			},
			sortedData() {
				const {order, prop} = this.sort
				if (order === 'ascending' && prop !== null) {
					if (prop === 'term') {
						return this.tableData.slice().sort((a, b) => a[prop].localeCompare(b[prop]))
					}
					return this.tableData.slice().sort((a, b) => a[prop] - b[prop])
				} else if (order === 'descending' && prop !== null) {
					if (prop === 'term') {
						return this.tableData.slice().sort((a, b) => b[prop].localeCompare(a[prop]))
					}
					return this.tableData.slice().sort((a, b) => b[prop] - a[prop])
				}
				return this.tableData.slice()
			}
		},
		methods: {
			cellFormatter(row, column, cellValue, index) {
				if (/(estimatedRevenue)/i.test(column.property)) {
					return '$' + Number(cellValue || null).toFixed(2)
				}
				else if (/epc/i.test(column.property)) {
					const formatted = cellValue.toFixed(3)
					return '$' + formatted.substring(0, formatted.length - 1)
				}
				else if (/date/i.test(column.property)) {
					return this.groupByDays ? Moment(Number(cellValue) * 1000).format('YYYY-MM-DD') : this.dateRange
				}
				return cellValue
			},
			sortChange({prop, order}) {
				this.sort = {prop, order}
			}
		}
	}
</script>

<style>
	.jscx-terms-table.el-table .el-table__body tr:hover > td.el-table__cell {
		background-color: unset;
	}
	.jscx-terms-table.el-table th.el-table__cell > .cell {
		text-transform: capitalize;
	}
</style>