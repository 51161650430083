import common from '@/common/mixins/common.js'
import page from '@/common/mixins/page.js'
import {
	mapActions
} from 'vuex'
import Moment from 'moment'
export default {
	inject: ['adminLayout'],
	mixins: [common, page],
	data() {
		return {
			queryForm: {
				domainNames: [],
				date: [
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
					Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
				],
				groupByDays: false,
				terms: []
			},
			tableData: [],
			q: {
				dateFrom: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD'),
				dateTo: Moment().startOf('day').add(-1, 'day').format('YYYY-MM-DD')
			},
			namespace: '',
			fetchOnCreated: false
		}
	},
	created() {
		const {
			dateFrom,
			dateTo,
			domainNames,
			groupByDays,
			terms
		} = this.$route.query
		if (dateFrom && dateTo && /\d{4}-\d{2}-\d{2}/.test(dateFrom) && /\d{4}-\d{2}-\d{2}/.test(dateTo)) {
			this.queryForm.date = [dateFrom, dateTo]
			this.q.dateFrom = dateFrom
			this.q.dateTo = dateTo
		}
		if (domainNames !== undefined && domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
			this.queryForm.domainNames = domainNames.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g)
		}
		if (groupByDays && /^(true)|(false)$/.test(groupByDays)) {
			this.queryForm.groupByDays = groupByDays === 'true'
			this.groupByDays = this.queryForm.groupByDays
		}
		if (terms) {
			this.queryForm.terms = terms.split(',').map(v => v.trim()).filter(v => v!=='')
		}
		this.searchEvent(false)
	},
	methods: {
		buildQueryForm(queryForm) {
			let query = {};
			for (let key in queryForm) {
				if (queryForm.hasOwnProperty(key) && (queryForm[key].length > 0 || typeof(queryForm[key]) === 'boolean')) {
					if (key === 'date') {
						const [dateFrom, dateTo] = queryForm[key]
						query['dateFrom'] = dateFrom
						query['dateTo'] = dateTo
					} 
					else if (key === 'domainNames') {
						query['domainNames'] = queryForm['domainNames'].reduce((lhs, rhs) => {
							if (rhs.match(/\b[a-zA-Z0-9.-]+\.[a-z]+\b/g) !== null) {
								lhs.push(rhs)
							}
							return lhs
						}, [])
					}
					else {
						query[key] = queryForm[key]
					}
				}
			}
			return query;
		},
		buildSearch(query) {
			let search = []
			for (let key in query) {
				if (Array.isArray(query[key])) {
					search.push(`${key}=${query[key].join(',')}`)
				} else {
					search.push(`${key}=${query[key]}`)
				}
			}
			return search.join('&')
		},
		searchEvent(showErr=true) {
			this.q = this.buildQueryForm(this.queryForm)
			const search = this.buildSearch(this.q)
			history.replaceState(this.q, '', `${window.location.pathname}?${search}`)
			this.getList()
		},
		handleRawData(content) {
			this.tableProps = {...this.q}
			this.tableData = content.map(v => {
				const clicks = Number(v.clicks)
				const epc = clicks > 0 ? (Number(v.estimatedRevenue) / clicks) : 0
				return {
					...v,
					clicks,
					epc
				}
			})
			this.doLayout()
		},
		doLayout() {},
		async getListUrl() {
			if (this.namespace === '') return
			const trigger = this.trigger
			return this.$store.dispatch(`${this.namespace}/getTerms`, {
				params: {
					...this.q
				},
				trigger
			})
		},
		downloadTask() {
			const q = this.buildQueryForm(this.queryForm)
			this.$store.commit('download-manager/addTask', {
				action: `${this.namespace}/getTerms`,
				payload: {...q},
				title: `JSCX_Terms_(${q.dateFrom}~${q.dateTo})`,
				dispatch: this.$store.dispatch,
				requestHandler:(payload, pagable) => {
					return {params: payload}
				},
				responseHandler:(content) => {
					return {
						content: content.map(v => {
							const _epc = Number(v.estimatedRevenue) / Number(v.clicks)
							const formatted = (isFinite(_epc) ? Number(_epc).toFixed(3) : '0.000')
							const epc = Number(formatted.substring(0, formatted.length - 1))
							return {...v, clicks: Number(v.clicks), visits: Number(v.visits), epc}
						}),
						totalPages: 1
					}
				}
			})
		}
	}
}